import React, { Fragment, useMemo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { CaretDownIcon } from '@/atoms/Icons/CaretDownIcon'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CaptionMD } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'

export interface SelectProps {
  disabled?: boolean
  divClassName?: string
  label?: string
  labelClassName?: string
  name: string
  onChange?: (value: string) => void
  options?: Array<string | SelectOption>
  placeholder?: string
  selectClassName?: string
  value?: string | null
  hasBadge?: boolean
  optionsClassName?: string
  wrapOptions?: boolean
}

export type SelectOption = {
  display: string
  value: string | number
  badge?: number
}

export const Select: React.FC<SelectProps> = ({
  disabled = false,
  divClassName,
  label,
  labelClassName,
  name,
  onChange = () => null,
  options = [],
  placeholder = '--- Select an Option ---',
  selectClassName,
  value,
  hasBadge = false,
  optionsClassName,
  wrapOptions = false,
  ...rest
}) => {
  const { isDarkMode } = useThemeContext()

  const buttonDisplayValue = useMemo(() => {
    const matchingOption = options.find((o) => {
      if (typeof o === 'string') {
        return o === value
      } else {
        return o.value === value
      }
    })
    return typeof matchingOption === 'string' ? matchingOption : matchingOption?.display
  }, [options, value])

  return (
    <div className={classNames(divClassName)}>
      {label && (
        <label
          htmlFor={name}
          className={classNames('mb-1', labelClassName, {
            'opacity-40': disabled,
          })}
        >
          <CaptionMD color={isDarkMode ? 'core-gray-400' : 'core-gray-700'}>{label}</CaptionMD>
        </label>
      )}
      <Listbox name={name} onChange={onChange} value={value} disabled={disabled} {...rest}>
        <div className={classNames('relative mt-1', selectClassName)}>
          <Listbox.Button
            className={classNames(
              'group relative w-full border-[1.5px] rounded-[10px] p-4 photon-caption-md text-left cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500',
              {
                'border-core-gray-800': isDarkMode,
                'border-core-gray-300': !isDarkMode,
                'bg-white text-black': !isDarkMode && !disabled,
                'bg-core-gray-900 text-white': isDarkMode && !disabled,
                'bg-core-gray-100': disabled && !isDarkMode,
                'bg-core-gray-600': disabled && isDarkMode,
              },
            )}
          >
            <span className={classNames('truncate flex items-start', { 'text-core-gray-400': !value })}>
              <span>{buttonDisplayValue || placeholder}</span>
              {hasBadge && <span className="z-20 h-[10px] w-[10px] rounded-2xl bg-red"></span>}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <CaretDownIcon
                aria-hidden="true"
                color="core-gray-400"
                size={20}
                className="transition-transform group-data-[headlessui-state=open]:rotate-180"
              />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={classNames(
                'absolute w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 z-10',
                { 'bg-white text-black': !isDarkMode, 'bg-core-gray-900 text-white': isDarkMode },
                optionsClassName,
              )}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={typeof option === 'string' ? option : option.value}
                  className={({ active }) =>
                    classNames('select-none relative py-2 pl-10 pr-4 cursor-pointer', {
                      'font-medium': active,
                    })
                  }
                  value={typeof option === 'string' ? option : option.value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames('flex gap-2', {
                          'font-semibold': selected,
                          truncate: !wrapOptions,
                        })}
                      >
                        {typeof option === 'string' ? option : option.display}
                        {typeof option !== 'string' && option.badge !== undefined && (
                          <span className="rounded-xl bg-red px-2 text-center text-[10px] font-semibold text-white lg:px-3">
                            {option.badge}
                          </span>
                        )}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex w-8 items-center pl-1">
                          <CheckIcon aria-hidden="true" color={!isDarkMode ? 'black' : 'white'} size={32} />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
