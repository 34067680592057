import React from 'react'
import classNames from 'classnames'
import { InternalLink } from '@/atoms/InternalLink'
import { CaptionLG } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface RegionalAvailabilityWarningProps {
  className?: string
}

export const RegionalAvailabilityWarning: React.FC<RegionalAvailabilityWarningProps> = ({ className }) => {
  const { t } = useTranslate('guild')
  return (
    <div className={classNames('mb-6 w-full rounded-2xl bg-info-200/50 px-6 py-4', className)}>
      <CaptionLG weight="medium" color="black">
        <Translate i18nKey="limitedAvailabilityWarning" t={t}>
          Due to our international distribution agreements, some titles may not be available in your region. To see a
          full list of shows available to Guild members in your area, click{' '}
          <InternalLink className="underline" target="_blank" href={paths.whatsInMyRegion.index}>
            here
          </InternalLink>
          .
        </Translate>
      </CaptionLG>
    </div>
  )
}
