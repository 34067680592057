import React, { ChangeEventHandler, HTMLInputTypeAttribute, ReactNode, useState } from 'react'
import classNames from 'classnames'

export interface InputProps {
  className?: string
  containerClassName?: string
  labelClassName?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  isValid?: boolean
  label: ReactNode
  name?: string
  onBlur?: ChangeEventHandler<HTMLInputElement>
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  value: string
  usePlaceholderAsLabel?: boolean
  inputClasses?: string
  maxLength?: number
  pattern?: string
}

/**
 * @deprecated Use import { PhotonInput } from '@/atoms/PhotonInput'
 */
export const Input: React.FC<InputProps> = ({
  className,
  containerClassName,
  labelClassName = 'text-gray-600',
  disabled = false,
  error = false,
  helperText = '',
  isValid = true,
  label,
  name = '',
  onBlur,
  onChange,
  pattern,
  placeholder,
  required = false,
  type = 'text',
  value,
  usePlaceholderAsLabel = false,
  maxLength,
  inputClasses = 'border border-t-0 border-r-0 border-l-0 w-full !duration-[0ms] focus-visible:ring-transparent focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-offset-transparent focus-visible:border-oxide focus-visible:border-b-2',
}) => {
  const [touched, setTouched] = useState(false)
  const [isActive, setActive] = useState(false)

  const isValidName = touched && !isValid

  return (
    <label className={classNames('mt-2 block', containerClassName)}>
      <div
        className={classNames('-mb-6 pt-6 duration-300 text-sm relative', labelClassName, {
          '!mb-0 !pt-0': usePlaceholderAsLabel || isActive || value,
        })}
      >
        {label}
      </div>
      <input
        className={classNames(inputClasses, isValidName ? 'border-red border-b-2' : 'border-gray-600', className)}
        data-testid={label}
        id={name}
        onBlur={(e) => {
          setActive(false)
          setTouched(true)
          onBlur?.(e)
        }}
        onChange={onChange}
        onFocus={() => setActive(true)}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        disabled={disabled}
        name={name}
        maxLength={maxLength}
        pattern={pattern}
      />
      {error && <span className="float-right text-sm text-red">{helperText}</span>}
    </label>
  )
}
