import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Durations } from '@/constants/durations'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { logger } from '../logging'

export type UtmParameters = {
  campaign?: string
  medium?: string
  source?: string
  content?: string
  term?: string
}

// we can add more as needed, this is nice for compiler
type StickyAttributionNames = 'guild-attribution'

interface UseStickyAttributionArgs {
  name: StickyAttributionNames
  analytics: UtmParameters
  // duration in millis
  duration: number
}

type StickyAttributionStorage = {
  name: StickyAttributionNames
  analytics: UtmParameters
  expiresAt: string
}

export const setStickyAttribution = ({ name, analytics, duration }: UseStickyAttributionArgs) => {
  if (typeof localStorage === 'undefined') return

  if (hasAnalytics(analytics)) {
    localStorage.setItem(
      name,
      JSON.stringify({ name, analytics, expiresAt: new Date(new Date().getTime() + duration).toISOString() }),
    )
  }
}

export const getStickyAttribution = (name: StickyAttributionNames) => {
  try {
    const stored = localStorage.getItem(name)
    if (stored) {
      const parsed = JSON.parse(stored) as StickyAttributionStorage
      const expiresAtMillis = new Date(parsed.expiresAt).getTime()
      const nowMillis = new Date().getTime()
      const isExpired = expiresAtMillis < nowMillis
      if (isExpired) {
        localStorage.removeItem(name)
      } else {
        return parsed
      }
    }
  } catch (e) {
    logger().warn('Error retrieving StickyAttribution', { name }, e as Error)
    return null
  }

  return null
}

export const useStickyAttribution = (name: StickyAttributionNames) => {
  const { isReady, query } = useRouter()

  useEffect(() => {
    if (isReady) {
      // we don't want to lose attribution if the user came back and clicked Guild in the sitenav, but we do want to track sitenav attribution generally speaking
      if (query.utm_source !== 'sitenav') {
        setStickyAttribution({
          name,
          analytics: {
            campaign: getItemOrFirstEntry(query.utm_campaign),
            source: getItemOrFirstEntry(query.utm_source),
            medium: getItemOrFirstEntry(query.utm_medium),
            term: getItemOrFirstEntry(query.utm_term),
            content: getItemOrFirstEntry(query.utm_content),
          },
          duration: 7 * Durations.ONE_DAY_IN_MILLISECONDS,
        })
      }
    }
  }, [isReady, query, name])
}

function hasAnalytics(analytics: UtmParameters) {
  return Boolean(analytics?.campaign || analytics.content || analytics.medium || analytics.term || analytics.source)
}
